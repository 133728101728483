.form {
  font-family:  'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.form-title {
  padding: 20px 0;
  font-size: 4rem;
  display: block;
  color: #bd4f09;
  text-align: center;
  margin: 0 auto;
  @media (max-width: 600px) {
    margin: 0 auto;
    left: 0px;
  }
}

.form label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.form input[type="text"],
.form input[type="email"],
.form textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
}

.form input[type="submit"] {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #bd4f09;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form input[type="submit"]:hover {
  background-color: #e97407;
}

.form textarea {
  height: 100px;
  resize: vertical;
}

.thankYouMessage {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.thankYouMessage h2 {
  color: #bd4f09;
  font-size: 24px;
  margin-bottom: 10px;
}

.thankYouMessage p {
  font-size: 18px;
  color: #333;
}

@media (max-width: 600px) {
  .form-title {
    font-size: 3.5rem;
  }
}