/* styles.css */
body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background: #f7f7f7;
  color: #333;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 55px;
  background-image: linear-gradient(rgba(7, 49, 32, 1), rgba(255, 0, 0, 0));
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100px;
}

.navbar-left .navbar-brand {
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
  @media (max-width: 515px) {
    font-size: 1rem;
  }
}

.navbar-right a {
  margin-left: 20px;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  @media (max-width: 515px) {
    font-size: 0.8rem;
  }
}

.navbar-right a:hover {
  color: #ccc;
}

body {
  padding-top: 100px; /* Offset for the fixed navbar */
}

.content {
  padding: 20px;
  line-height: 1.6;
}

@media (max-width: 480px) {
  .navbar {
    padding: 0 20px;
  }
}