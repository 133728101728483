/* styles.css */
body {
  margin: 0;
  padding: 0;
  background: #F0F0F0;
  color: #333;
  /* background-image: url('leaf-pattern.png'); */
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 350px, 350px;
  padding-top: 120px;
}

.pricing-page {
  max-width: 900px;
  margin: 0 auto 40px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  background-clip: padding-box;
}

.pricing-section {
  padding: 20px;
  background: linear-gradient(#DCE2E0 60%, #fff 40%);
  min-height: 500px;
  /* background-color: #DBE2E0; */
}

.pricing-section h2 {
  font-family:  'Montserrat', sans-serif;
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.4rem;
  color: #333;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  top: 20px;
  font-weight: 500;
}

.two {
  padding: 0 100px;
}

.pricing-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  top: 30px;
}

.pricing-card {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  width: 240px;
  margin: 20px;
  transition: transform 0.3s ease;
  border-radius: 4px;
}

/* .pricing-card:hover {
  transform: scale(1.01);
} */

.pricing-card h3 {
  margin: 15px;
  font-size: 1.2rem;
  color: #555;
  font-weight: 400;
}

.price {
  font-size: 1.4rem;
  color: #888;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-weight: 500;
}

.pricing-card p {
  margin: 8px 0;
  color: #666;
}

.spacing {
  height: 70px;
  background-color: #F0F0F0;
}

@media (max-width: 600px) {
  .two {
    padding: 0;
  }
  body {
    background-image: none;
  }
  .pricing-section {
    padding: 30px;
    background: linear-gradient(#DCE2E0 100%, #fff 0%);
    min-height: 500px;
    /* background-color: #DBE2E0; */
  }
}